var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('tab-primary', [_vm._v("개인회원")]), _c('tab-primary', [_vm._v("입점사 회원")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "max-width": "416"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("회원가입")]), _c('p', {
    staticClass: "txt txt--dark txt--sm"
  }, [_vm._v(" 환영합니다."), _c('br'), _vm._v(" 회원이 되셔서 할인쿠폰/마일리지 등 다양한 서비스를 받으세요. ")])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "href": `/join/certify?type=${_vm.USER_TYPES.PERSON.value}`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("회원가입")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--dark txt--sm"
  }, [_vm._v("SNS 간편 로그인시 회원가입을 진행해 주시기 바랍니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('kakao-login', {
    attrs: {
      "client_id": "21f7a966f13bf90dc3f3c6e0401f4445"
    }
  }, [_vm._v("카카오 로그인")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('naver-login', {
    attrs: {
      "client_id": "U5mxgu3NbIYiYj7likp6"
    }
  }, [_vm._v("네이버 로그인")])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "max-width": "416"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("회원가입")]), _c('p', {
    staticClass: "txt txt--dark txt--sm"
  }, [_vm._v(" 환영합니다."), _c('br'), _vm._v(" 회원이 되셔서 할인쿠폰/마일리지 등 다양한 서비스를 받으세요. ")])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "href": `/join/certify?type=${_vm.USER_TYPES.COMPANY.value}`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("회원가입")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }