var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center mb-20px mb-lg-26px"
  }, [_c('div', {
    staticClass: "w-60px h-60px w-lg-80px h-lg-80px rounded-pill secondary d-flex justify-center"
  }, [_c('v-icon', {
    staticClass: "white--text",
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("done")])], 1)]), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("회원가입 완료")])]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v(" " + _vm._s(_vm.name) + "(" + _vm._s(_vm.username) + ")님의 회원가입이"), _c('br'), _vm._v(" 성공적으로 완료되었습니다. ")])]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large v-btn--width-fixed",
    attrs: {
      "href": "/login"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("로그인")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large v-btn--width-fixed",
    attrs: {
      "href": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("홈으로 가기")])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }