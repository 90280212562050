var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("회원정보 입력")];
      },
      proxy: true
    }])
  }, [_vm.type === _vm.USER_TYPES.PERSON.value ? _c('join-input', {
    attrs: {
      "code": "join",
      "skin": "join-person"
    }
  }) : _vm._e(), _vm.type === _vm.USER_TYPES.COMPANY.value ? _c('join-input', {
    attrs: {
      "code": "join",
      "skin": "join-company"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }