<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <!-- <template slot="tit">회원가입</template> -->
            <v-tabs v-model="tab" grow class="mb-40px mb-lg-60px">
                <tab-primary>개인회원</tab-primary>
                <tab-primary>입점사 회원</tab-primary>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item align="center">
                    <v-card max-width="416" class="text-center">
                        <div class="tit-wrap">
                            <h2 class="tit">회원가입</h2>
                            <p class="txt txt--dark txt--sm">
                                환영합니다.<br />
                                회원이 되셔서 할인쿠폰/마일리지 등 다양한 서비스를 받으세요.
                            </p>
                        </div>
                        <v-row class="row--xxs">
                            <v-col cols="12">
                                <!-- <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large" block>회원가입</v-btn> -->
                                <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large" block :href="`/join/certify?type=${USER_TYPES.PERSON.value}`">회원가입</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <p class="txt txt--dark txt--sm">SNS 간편 로그인시 회원가입을 진행해 주시기 바랍니다.</p>
                            </v-col>
                            <v-col cols="12" md="6">
                                <kakao-login client_id="21f7a966f13bf90dc3f3c6e0401f4445">카카오 로그인</kakao-login>
                            </v-col>
                            <v-col cols="12" md="6">
                                <naver-login client_id="U5mxgu3NbIYiYj7likp6">네이버 로그인</naver-login>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item align="center">
                    <v-card max-width="416" class="text-center">
                        <div class="tit-wrap">
                            <h2 class="tit">회원가입</h2>
                            <p class="txt txt--dark txt--sm">
                                환영합니다.<br />
                                회원이 되셔서 할인쿠폰/마일리지 등 다양한 서비스를 받으세요.
                            </p>
                        </div>
                        <v-row class="row--xxs">
                            <v-col cols="12">
                                <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large" block :href="`/join/certify?type=${USER_TYPES.COMPANY.value}`">회원가입</v-btn>
                                <!-- <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large" block>회원가입</v-btn> -->
                            </v-col>
                            <!-- <v-col cols="12" md="6">
                                <kakao-login client_id="">카카오 간편가입</kakao-login>
                            </v-col>
                            <v-col cols="12" md="6">
                                <naver-login client_id="">네이버 간편가입</naver-login>
                            </v-col> -->
                        </v-row>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import Axios from "axios";
import CryptoAES from "@/plugins/crypto-aes";
import { mapActions } from "vuex";
import { initUser, rules, USER_TYPES } from "@/assets/variables";
import { btn_primary, btn_tertiary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
// import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";
import IconCheck from "@/components/client/icons/icon-check.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";
import NaverLogin from "@/components/plugins/naver/naver-login.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapSubtitle,
        VAddressField,
        VerticalFormTable,
        VPasswordConfirmField,
        // TermsOfAgreementsShort,
        IconCheck,
        TabPrimary,
        KakaoLogin,
        NaverLogin,
    },
    data: () => ({
        btn_primary,
        btn_tertiary,

        form: initUser(),

        tab: "form",
        // tab: "result",

        USER_TYPES,

        loading: false,
        isValid: false,
        isTermsValid: undefined,
    }),
    computed: {
        promoterCode() {
            return this.$route.query.promoterCode || null;
        },
        accessToken() {
            return this.$route.query.accessToken;
        },
        isSnsJoin() {
            return !!this.accessToken;
        },
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
        items() {
            const items = [
                {
                    key: "username",
                    term: "아이디",
                    type: "text",
                    required: true,
                    placeholder: "아이디를 입력하세요",
                    rules: rules.username,
                },
                {
                    key: "password",
                    term: "비밀번호",
                    type: "password",
                    required: true,
                    placeholder: "비밀번호를 입력하세요",
                    rules: rules.password,
                    hides: this.isSnsJoin,
                },
                {
                    key: "password",
                    term: "비밀번호 확인",
                    required: true,
                    placeholder: "동일한 비밀번호를 재입력하세요.",
                    hides: this.isSnsJoin,
                },
                {
                    key: "name",
                    term: "이름",
                    type: "text",
                    required: true,
                    placeholder: "이름을 입력하세요.",
                    rules: rules.name,
                },
                {
                    key: "email",
                    term: "이메일",
                    type: "email",
                    required: true,
                    placeholder: "이메일을 입력하세요.",
                    rules: rules.email,
                },
                {
                    key: "phone",
                    term: "연락처",
                    type: "phone",
                    required: true,
                },
                {
                    key: "businessRegistration",
                    term: "사업자등록증",
                    type: "file",
                    required: true,
                    hides: this.form.type != USER_TYPES.COMPANY.value,
                },
                {
                    key: "referrer",
                    term: "추천인 코드",
                    type: "text",
                },
            ];

            return items.filter(({ hides }) => !hides).map((item) => ({ ...item, outlined: true }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        accessToken() {
            this.init();
        },
    },
    methods: {
        // ...mapActions("metaPixel", ["fbq"]),
        // ...mapActions("naverWcs", ["wcs_do_cnv"]),
        async init() {
            let { promoterCode } = this;

            if (this.accessToken) {
                if (this.loading) return;
                else this.loading = true;

                try {
                    const { user = {} } = (await Axios.get("/api/v1/me/info", { headers: { Authorization: `Bearer ${this.accessToken}` } }))?.data || {};

                    let { _id, name, email, birthday, sex, phone } = user;
                    birthday = birthday?.toDate?.() || birthday;

                    this.form = initUser({ _id, name, email, birthday, sex, phone, promoterCode });
                } catch (error) {
                    this.$handleError(error);
                    this.$router.replace({ path: "/join" });
                } finally {
                    this.loading = false;
                }
            } else {
                this.form = initUser({ promoterCode });
            }
        },

        emit() {
            this.form = initUser(this.form);
        },

        async join() {
            if (!this.validates()) return;
            if (this.loading) return;
            this.loading = true;

            try {
                let { _businessRegistration, businessRegistration, ...form } = this.form;
                form.password = CryptoAES.encrypt(this.form.password);

                // POST, PUT user && POST login
                if (this.isSnsJoin) {
                    const { accessToken } = this;
                    delete form.password;
                    try {
                        await Axios.put("/api/v1/me/info", form, { headers: { Authorization: `Bearer ${accessToken}` } });
                    } catch (error) {
                        this.$handleError(error);
                        throw new Error();
                    }
                    await this.$store.dispatch("login", { accessToken });
                } else {
                    await api.v1.users.post(form);
                    await this.$store.dispatch("login", { username: this.form.username, password: this.form.password });
                }

                // POST resource
                if (form.type == USER_TYPES.COMPANY.value) {
                    if (businessRegistration instanceof File) {
                        businessRegistration = (await api.v1.files.post({ path: "businessRegistration" }, businessRegistration))?.file;
                    }
                    _businessRegistration = businessRegistration?._id || null;
                    await api.v1.me.put({ _businessRegistration });
                }

                this.tab = "result";

                // this.fbq({ type: "CompleteRegistration" });
                // this.wcs_do_cnv({ type: "2" });
            } finally {
                this.loading = false;
            }
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    width: 100%;
    border-radius: var(--btn-border-radius-xxl);
    overflow: hidden;
    > .v-btn.v-btn {
        margin: 0;
        border-radius: 0;
        opacity: 1;
        border-width: var(--btn-outline-border-width);
        background-color: #fff !important;
        border-color: inherit;
        &--active {
            color: #fff !important;
            background-color: inherit !important;
            &:before {
                opacity: 0;
            }
        }
        &.v-size--xx-large {
            &:first-child {
                border-top-left-radius: var(--btn-border-radius-xxl);
                border-bottom-left-radius: var(--btn-border-radius-xxl);
            }
            &:last-child {
                border-top-right-radius: var(--btn-border-radius-xxl);
                border-bottom-right-radius: var(--btn-border-radius-xxl);
            }
        }
    }
}
</style>
