var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("본인인증")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "width": "26",
      "height": "26",
      "color": "grey",
      "outlined": ""
    }
  }, [_c('v-icon', {
    staticClass: "grey--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("정보입력")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "width": "26",
      "height": "26",
      "color": "grey",
      "outlined": ""
    }
  }, [_c('v-icon', {
    staticClass: "grey--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1), _c('v-col', {
    staticClass: "font-weight-light",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("가입완료")])], 1)], 1), _c('h3', {
    staticClass: "tit tit--xxs text-center"
  }, [_vm._v("가입을 위한 본인인증을 진행해주세요.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large v-btn--width-fixed",
    on: {
      "click": _vm.certify
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("휴대폰 인증")])], 1), _c('v-sheet', {
    staticClass: "mx-auto mt-40px",
    attrs: {
      "max-width": "700"
    }
  }, [_c('div', {
    staticClass: "txt"
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("인증완료 시 이름/휴대폰번호는 인증된 정보로 갱신되며, 생일 및 통신사 정보가 추가로 수집됩니다.")]), _c('p', {
    staticClass: "dot"
  }, [_vm._v(" 실명확인이 되지 않는 경우 아래의 실명인증기관에 실명등록을 요청할 수 있습니다."), _c('br'), _vm._v(" 한국신용정보(주) 1588-2486 ")]), _c('p', {
    staticClass: "dot"
  }, [_vm._v("인증완료 시 이름/휴대폰번호는 인증된 정보로 갱신되며, 생일 및 통신사 정보가 추가로 수집됩니다.")]), _c('p', {
    staticClass: "dot"
  }, [_vm._v("타인의 정보 및 주민등록번호를 부정하게 사용하는 경우 3년 이하의 징역 또는 1천만원 이하의 벌금에 처해질 수 있습니다. (주민등록법 제 37조)")])])])], 2), _c('kcp-cert', {
    ref: "kcpCert",
    on: {
      "input": _vm.verify
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }