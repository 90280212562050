<template>
    <client-page>
        <v-form v-model="isValid">
            <page-section titAlign="text-center" class="page-section--first page-section--last">
                <template slot="tit"> 약관동의 </template>
                <v-sheet max-width="416" class="mx-auto">
                    <div class="txt txt--xl txt--dark text-center"><span class="font-weight-bold">Moago</span> 서비스 이용약관에 동의해주세요.</div>
                    <div class="pt-30px pt-md-40px">
                        <terms-of-agreements-short v-model="terms" v-bind="{ params: { code: { $nin: ['order'] } }}" @isValid="(isValid) => (isTermsValid = isValid)"/>
                    </div>
                    <div class="btn-wrap">
                        <v-btn v-bind="{ ...btn_primary, disabled }" class="v-size--xx-large" block @click="join">다음</v-btn>
                    </div>
                </v-sheet>
            </page-section>
        </v-form>
    </client-page>
</template>

<script>
import { btn_primary, btn_secondary, btn_icon } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TermsOfAgreementsShort,
    },
    data: () => ({
        btn_icon,
        btn_primary,
        btn_secondary,

        isValid: false,
        isTermsValid: false,
        terms: [],
    }),
    methods:{
        join(){
            this.$store.dispatch("agreements", { _terms: this.terms });
            // this.$router.push(`/join/form?_certification=${this._certification}&type=${this.type}`);
            this.$router.push({ path: "/join/form", query: { ...this.query } });
        }
    },
    computed: {             
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
        query() {
            return this.$route.query;
        }
    },
};
</script>
