var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(" 약관동의 ")]), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "416"
    }
  }, [_c('div', {
    staticClass: "txt txt--xl txt--dark text-center"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Moago")]), _vm._v(" 서비스 이용약관에 동의해주세요.")]), _c('div', {
    staticClass: "pt-30px pt-md-40px"
  }, [_c('terms-of-agreements-short', _vm._b({
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.terms,
      callback: function ($$v) {
        _vm.terms = $$v;
      },
      expression: "terms"
    }
  }, 'terms-of-agreements-short', {
    params: {
      code: {
        $nin: ['order']
      }
    }
  }, false))], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.join
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    disabled: _vm.disabled
  }), false), [_vm._v("다음")])], 1)])], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }